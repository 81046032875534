import QueryString from 'qs'
import {CONFIG, CONNECTION} from '../../helper'
// import * as Sentry from '@sentry/react'

const path = process.env.REACT_APP_ENDPOINT_COMPANY_ACTIVITY_LOG || '/company-activity-log'

export const createCompanyActivityLog = async (data: any) => {
  const config = CONFIG({
    url: `${path}/create`,
    method: 'POST',
    data: data,
  })
  return await CONNECTION(config)
}

export const getCompanyActivityLog = async (query: any) => {
  const qs = QueryString.stringify(query)
  const config = CONFIG({
    url: `${path}/get?${qs}`,
    method: 'GET',
  })
  return await CONNECTION(config)
}
