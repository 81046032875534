import {Modal, ModalBody} from 'reactstrap'
import {GiButton} from '../system-ui'
import './LayoutConfirm.scss'

interface LayoutConfirmProps {
  onClose: () => void
  onAccept: () => void
  textClose?: string
  textAccept?: string
  classNameClose?: string
  hideClose?: boolean
  hideAccept?: boolean
  classNameAccept?: string
  title: any
  message: any
  isOpen: boolean
  size?: 'sm' | 'lg' | 'xl'
  loading?: boolean
  loadingText?: string
  buttonAcceptProps?: any
}
const LayoutConfirm = (props: LayoutConfirmProps) => {
  return (
    <Modal
      centered
      backdrop='static'
      keyboard={false}
      className='custom-ui-confirm'
      isOpen={props.isOpen}
      size={props.size || 'md'}
    >
      <ModalBody>
        <div className='box-title-confirm' dangerouslySetInnerHTML={{__html: props.title}} />
        <div className='box-content-confirm'>{props.message}</div>
        <div className='box-action-confirm'>
          {!props.hideClose && (
            <GiButton
              color='light'
              className={`${props.classNameClose}`}
              disabled={props.loading}
              onClick={props.onClose}
            >
              {props.textClose || 'Cancel'}
            </GiButton>
          )}
          {!props.hideAccept && (
            <GiButton
              color='light'
              className={`text-danger ${props.classNameAccept}`}
              disabled={props.loading}
              onClick={props.onAccept}
              {...props.buttonAcceptProps}
            >
              {props.loading ? props.loadingText || 'Loading..' : props.textAccept || 'Yes'}
            </GiButton>
          )}
        </div>
      </ModalBody>
    </Modal>
    // <div className='custom-ui-confirm'>
    //   <h1>{props.title}</h1>
    //   <div>{props.message}</div>
    //   <GiButton color='light' className={`${props.classNameClose}`} onClick={props.onClose}>
    //     {props.textClose || 'No'}
    //   </GiButton>
    //   <GiButton color='light' className={`${props.classNameAccept}`} onClick={props.onAccept}>
    //     {props.textAccept || 'Yes, Delete it!'}
    //   </GiButton>
    // </div>
  )
}

export default LayoutConfirm
