import {CONFIG, CONNECTION} from '../../helper'

const endpoint = process.env.REACT_APP_PATH_CORPORATE

export const GetAllDraftOrder = async (
  corporate_id: string,
  page: number = 1,
  limit: number = 20,
  status?: string
) => {
  let statusQuery = ''
  if (status) {
    statusQuery = '&status=' + status
  }
  const config = CONFIG({
    url:
      endpoint +
      '/draft-order/get/all?corporate_id=' +
      corporate_id +
      '&page=' +
      page +
      '&limit=' +
      limit +
      statusQuery,
  })
  return await CONNECTION(config)
}

export const GetDraftOrderByID = async (id: string) => {
  const config = CONFIG({
    url: endpoint + '/draft-order/get/' + id,
  })
  return await CONNECTION(config)
}

export const CreateDraftOrder = async (data: any) => {
  const config = CONFIG({
    url: endpoint + '/draft-order/create',
    method: 'POST',
    data: {
      created_by: 'string',
      details: {
        ...data,
      },
    },
  })
  return await CONNECTION(config)
}

export const UpdateDraftOrder = async (id: string, newData: any, skipCreateLog?: boolean) => {
  let skipLog = skipCreateLog ? '?skip_activity_log=' + skipCreateLog : ''
  const config = CONFIG({
    url: endpoint + '/draft-order/update/' + id + skipLog,
    method: 'PUT',
    data: {
      'id:updated_by': 'string',
      details: {
        ...newData,
      },
    },
  })
  return await CONNECTION(config)
}

export const DeleteDraftOrder = async (id: string) => {
  const config = CONFIG({
    url: endpoint + '/draft-order/delete/' + id,
    method: 'DELETE',
  })
  return await CONNECTION(config)
}
