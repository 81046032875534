import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsCurrent} from '../../../helpers'
import {getCorporateOrdersGO} from '../../../../app/services/corporate/CorporateServices'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../app/redux/rootReducer'
import {UncontrolledTooltip} from 'reactstrap'
import * as Sentry from '@sentry/react'
import {GetAllDraftOrder} from '../../../../app/services/corporate/DraftOrderService'

type Props = {
  to: string
  title: string
  icon?: string | JSX.Element
  fontIcon?: string
  hasBullet?: boolean
  isRestricted?: boolean
}

const AsideMenuItem: React.FC<Props> = ({children, to, title, icon, isRestricted}) => {
  const {corporate_id, corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const [ordersWaiting, setOrdersWaiting] = useState(0)
  const {pathname} = useLocation()
  // const isActive = checkIsActive(pathname, to)
  const isCurrent = checkIsCurrent(pathname, to)

  useEffect(() => {
    if (to === '/send-gifts') {
      setOrdersWaiting(0)
      const fetchOrders = async () => {
        try {
          const [res1, res2] = await Promise.all([
            getCorporateOrdersGO({
              corporate_id,
              order_status: 'wait-for-approval',
              limit: 1,
            }),
            GetAllDraftOrder(corporate_id, 1, 1, 'waiting-for-approval'),
          ])
          const totalOrders =
            (res1.pagination_info?.total || 0) + (res2.pagination_info?.total || 0)
          setOrdersWaiting(totalOrders)
        } catch (err) {
          Sentry.captureException(err)
          console.log('err load waiting for approval', err)
        }
      }
      fetchOrders()
    }
  }, [corporate_id, to])

  // if (to === 'manage/user' || to === '/soa') {
  //   return <div className='bitest'>{title}</div>
  // }

  // remove activate qr gift for all country except SG
  let magicCard = process.env.REACT_APP_QRGIFT_NAME || 'Magic Card'
  const magicCardPath = magicCard.toLowerCase().replace(' ', '-')
  if (
    to === `/send-gifts/activate-${magicCardPath}` &&
    corporate_detail &&
    corporate_detail['country'] !== 'SG'
  ) {
    return null
  }

  const menuLinkItem = () => {
    let titleString = title
    let title1 = titleString
    let title2 = ''
    if (titleString.includes('>')) {
      let arrTitleString = titleString.split('>')
      title1 = arrTitleString[0].trim()
      if (arrTitleString.length > 1) {
        title2 = arrTitleString[1].trim()
      }
    }
    return (
      <>
        {icon ? (
          <span className='menu-icon'>{icon}</span>
        ) : (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        <span className='menu-title' style={{position: 'relative'}}>
          {title1}
          {title2 && (
            <>
              <br />
              <span className='menu-subtitle'>{title2}</span>
            </>
          )}
          {to === '/send-gifts' && ordersWaiting > 0 && (
            <>
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  position: 'absolute',
                  left: '130px',
                  background: 'var(--gf-error-200)',
                  borderRadius: '5px',
                  display: 'flex',
                }}
                id={'warning_order_approval'}
              ></div>
              <UncontrolledTooltip placement='top' target={'warning_order_approval'}>
                {ordersWaiting} orders waiting for approval
              </UncontrolledTooltip>
            </>
          )}
        </span>
      </>
    )
  }

  // console.log(isRestricted, 'testing menu')
  const isExternalURL = (url: any) => url.indexOf('http://') === 0 || url.indexOf('https://') === 0
  return (
    <div className='menu-item'>
      {to === '/manage/user' || to === '/soa' ? (
        isRestricted ? (
          <div className='menu-link without-sub menu-disabled'>{menuLinkItem()}</div>
        ) : (
          <Link
            className={clsx(`menu-link without-sub ${isRestricted && 'menu-disabled'}`, {
              active: isCurrent,
            })}
            to={to}
          >
            {menuLinkItem()}
          </Link>
        )
      ) : isExternalURL(to) ? (
        <a
          href={to}
          target='_blank'
          rel='noreferrer'
          className={clsx(`menu-link without-sub ${isRestricted && 'menu-disabled'}`, {
            active: isCurrent,
          })}
        >
          {menuLinkItem()}
        </a>
      ) : to.startsWith('/send-gifts') &&
        corporate_detail &&
        corporate_detail['transaction-settings'] &&
        corporate_detail['transaction-settings']['gfb-disable-order'] === '1' ? (
        <div className='menu-link without-sub menu-disabled'>{menuLinkItem()}</div>
      ) : (
        <Link className={clsx('menu-link without-sub', {active: isCurrent})} to={to}>
          {menuLinkItem()}
        </Link>
      )}

      {children}
    </div>
  )
}

export {AsideMenuItem}
