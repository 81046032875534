import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import 'react-toggle/style.css'
import {AppRoutes} from './app/routing/AppRoutes'
import store from './app/redux/store'

import 'react-toastify/dist/ReactToastify.css'

import {ToastContainer} from 'react-toastify'

import * as Sentry from '@sentry/react'
//import '../node_modules/@fe-giftano/ui-system-giftano/src/components/assets/css/style.css'

import {gfRequestID} from './app/helper/connection/connection'

//
;(async () => {
  let enableSentry = true
  let enableSentryReplays = false

  try {
    let respStrapi = await fetch(
      process.env.REACT_APP_BASEURL_STRAPI +
        '/business-dashboard?fields[0]=enable_sentry&fields[1]=enable_sentry_replays'
    )
    let respJson = await respStrapi.json()
    if (respJson) {
      // respJson = mapStrapiV4DataToV3(respJson);
      enableSentry =
        respJson.data.attributes.enable_sentry !== undefined
          ? respJson.data.attributes.enable_sentry
          : true
      enableSentryReplays =
        respJson.data.attributes.enable_sentry_replays !== undefined
          ? respJson.data.attributes.enable_sentry_replays
          : false
    }
  } catch {}

  const mode = process.env.REACT_APP_BUILD_MODE?.toLowerCase() || 'sandbox'
  if (enableSentry && window.location.hostname !== 'localhost') {
    let integrations: any[] = [new Sentry.BrowserTracing()]
    if (enableSentryReplays) {
      integrations.push(new Sentry.Replay())
    }
    Sentry.init({
      dsn: 'https://45964ce7b2e2456fa563ea1ac5a217ed@o1089454.ingest.sentry.io/6543068',
      integrations: integrations,
      environment: mode.toLowerCase(),
      initialScope: {
        tags: {
          team: 'front-end',
          'customer.b2b': true,
        },
      },
      denyUrls: [/https:\/\/static.cloudflareinsights.com\/beacon.min.js/i],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: mode === 'production' ? 0.5 : 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: mode === 'production' ? 0.1 : 1.0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      beforeSend(event: any, hint: any) {
        // send gf request id to sentry
        if (gfRequestID) {
          event.extra = event.extra || {}
          event.extra['gf-request-id'] = gfRequestID
        }
        return event
      },
    })
  }

  ReactDOM.render(
    <MetronicI18nProvider>
      <Provider store={store}>
        <ToastContainer />
        <AppRoutes />
      </Provider>
    </MetronicI18nProvider>,
    document.getElementById('root')
  )
})()
