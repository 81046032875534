import {IAction} from '../store'
import {ERROR_BLOG, LOADING_BLOG, SUCCESS_BLOG} from './BlogTypes'

interface ICatalogue {
  data: []
  status: 'pending' | 'loading' | 'success' | 'error' | 'default'
  error?: string
}

const InitialState: ICatalogue = {
  data: [],
  status: 'default',
}

const BlogReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}

  switch (action.type) {
    case LOADING_BLOG:
      newState.status = 'loading'
      break
    case SUCCESS_BLOG:
      newState.data = action.payload.data || []
      newState.status = 'success'
      break
    case ERROR_BLOG:
      newState.status = 'error'
      break
    default:
      break
  }

  return newState
}

export default BlogReducer
