import React, {Component, ErrorInfo, ReactNode} from 'react'
import * as Sentry from '@sentry/react'

interface Props {
  children: ReactNode
  fallback?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(_: Error): State {
    return {hasError: true}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
    Sentry.captureException(error)
  }

  render() {
    const isLocalhost = window.location.hostname === 'localhost'

    if (this.state.hasError && !isLocalhost) {
      if (this.props.fallback) {
        return this.props.fallback
      }
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
