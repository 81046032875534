import {IAction} from '../store'
import {ERROR_SOA, LOADING_SOA, SUCCESS_SOA} from './SoaTypes'

interface ICatalogue {
  data: {}
  period: {}
  status: 'pending' | 'loading' | 'success' | 'error' | 'default'
  error?: string
}

const InitialState: ICatalogue = {
  data: {},
  period: {},
  status: 'default',
}

const SoaReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}

  switch (action.type) {
    case LOADING_SOA:
      newState.status = 'loading'
      break
    case SUCCESS_SOA:
      newState.data = action.payload.data
      newState.period = action.payload.period
      newState.status = 'success'
      break
    case ERROR_SOA:
      newState.status = 'error'
      break
    default:
      break
  }

  return newState
}

export default SoaReducer
