import {CONFIG, CONNECTION} from '../../helper'
// const endpoint = process.env.REACT_APP_ENDPOINT_USER_MANAMGEMENT
const pathUserManagement = process.env.REACT_APP_PATH_CORPORATE
const pathRole = process.env.REACT_APP_PATH_USER
// const baseEndPoint = process.env.REACT_APP_BASEURL

export const getUser = async (userId: string) => {
  const config = CONFIG({
    url: pathUserManagement + '/user/' + userId,
  })
  return await CONNECTION(config)
}

export const getRoleUser = async () => {
  const config = CONFIG({
    url: pathRole + '/roles',
    method: 'GET',
  })

  return await CONNECTION(config)
}

export const getUserManagement = async (corporate_id: string) => {
  try {
    const config = CONFIG({
      url: pathUserManagement + '/user/get/all?corporate_id=' + corporate_id,
    })
    return await CONNECTION(config)
  } catch (er) {
    console.log('Corporate not found')
  }
}

export const postUserManagement = async (data: any, corporate_id: string) => {
  try {
    const config = CONFIG({
      url: pathUserManagement + '/user/create',
      method: 'POST',
      data: {...data, corporate_id: corporate_id, created_by: ''},
    })
    return await CONNECTION(config)
  } catch (error) {
    console.log('Corporate not found')
  }
}

export const deleteUserManagement = async (data: any) => {
  const {id} = data
  const config = CONFIG({
    url: pathUserManagement + '/user/delete/' + id,
    method: 'DELETE',
  })

  return await CONNECTION(config)
}

export const updateUserManagement = async (data: any) => {
  const {userid, role_id, full_name} = data
  let dataToSend: any = {}
  if (full_name) {
    dataToSend.full_name = full_name
    dataToSend.force_update_user = true
  }
  if (role_id) {
    dataToSend.role_id = role_id
  }
  const config = CONFIG({
    url: pathUserManagement + '/user/update/' + userid,
    data: dataToSend,
    method: 'PUT',
  })

  return await CONNECTION(config)
}

export const getbyidUserManagement = async (params: any) => {
  const {id} = params
  const config = CONFIG({
    url: pathUserManagement + '/user/get/' + id,
    method: 'GET',
  })

  return await CONNECTION(config)
}
