import {IAction} from '../store'
import {
  ERROR_APP_STRING_CORPORATE,
  LOADING_APP_STRING_CORPORATE,
  SUCCESS_APP_STRING_CORPORATE,
} from './AppStringCorporateTypes'

export interface IAppStringCorporate {
  data: any
  error?: any
  status: 'pending' | 'loading' | 'success' | 'error'
}

const initialState: IAppStringCorporate = {
  data: [],
  status: 'pending',
}

const AppStringCorporateReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_APP_STRING_CORPORATE:
      newState.status = 'loading'
      break
    case SUCCESS_APP_STRING_CORPORATE:
      newState.data = action.payload.data || []
      newState.status = 'success'
      break
    case ERROR_APP_STRING_CORPORATE:
      newState.status = 'error'
      newState.error = action.payload.error
      break
    default:
      break
  }
  return newState
}

export default AppStringCorporateReducer
