import {useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import {ConfirmProvider} from 'material-ui-confirm'
import ModalTopUp from '../../app/components/modals/ModalTopup'
import ModalCompany from './components/header/ModalCompany'
import ModalVerifyCompany from './components/header/ModalVerifyCompany'
import ModalContact from '../../app/components/contact-us/ModalContact'
import {useDispatch} from 'react-redux'
import {actShowModalContactUs} from '../../app/redux/contact-us/ContactUsActions'
import ModalSelectCreate from '../../app/pages/send-gifts/ModalSelectCreate'
import clsx from 'clsx'
import ModalFormAddInquiry from '../../app/pages/inquiry/ModalFormAddInquiry'
import ModalMobileWarning from '../../app/components/modals/ModalMobileWarning'
import ModalWarningReload from '../../app/components/modals/ModalWarningReload'
// import { CompanyProvider } from '../../app/components/initializer/AuthCompany'

const MasterLayout = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // get print only variable
  const params = new URLSearchParams(window.location.search)
  const printOnly = params.get('print_only')
  // get print only variable from url

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  const [openModalInquiry, setOpenModalInquiry] = useState(false)

  useEffect(() => {
    if (location.hash === '#contact_us') {
      dispatch(actShowModalContactUs())
    }

    if (location.hash === '#physical_gift_enquiry') {
      setOpenModalInquiry(true)
    } else {
      setOpenModalInquiry(false)
    }
  }, [dispatch, location.hash])

  return (
    <PageDataProvider>
      <ModalFormAddInquiry
        data={{scan_to_reward: 'on'}}
        open={openModalInquiry}
        onCloseModal={() => {
          setOpenModalInquiry(!openModalInquiry)
          // remove hash from url
          navigate(location.pathname)
        }}
      />
      {/* <CompanyProvider> */}
      <div className='page d-flex flex-row flex-column-fluid'>
        {!printOnly && <AsideDefault />}
        <div
          className={clsx('wrapper d-flex flex-column flex-row-fluid', {'p-0': printOnly})}
          id='kt_wrapper'
        >
          {!printOnly && <HeaderWrapper />}
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            {!printOnly && <Toolbar />}
            <div className='post d-flex flex-column' id='kt_post'>
              <Content>
                <ConfirmProvider>
                  <Outlet />
                </ConfirmProvider>
                {!printOnly && (
                  <>
                    <ModalVerifyCompany />
                    <ModalTopUp />
                    <ModalCompany />
                    <ModalContact />
                    <ModalSelectCreate />
                    <ModalWarningReload />
                  </>
                )}
                <ModalMobileWarning />
              </Content>
            </div>
          </div>
          {!printOnly && <Footer />}
        </div>
      </div>
      {/* </CompanyProvider> */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export default MasterLayout
