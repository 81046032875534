import {IAction} from '../store'
import {LOAD_ORDER_DETAILS, VIEW_GIFT_DETAILS} from './OrderDetailActionTypes'

export interface IOrderDetailState {
  data: any
  viewGiftDetail: any
  orderDetailId: string
}

const InitialState: IOrderDetailState = {
  data: {},
  viewGiftDetail: {},
  orderDetailId: '',
}

const OrderDetailReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOAD_ORDER_DETAILS:
      newState.data = action.payload.data
      newState.orderDetailId = action.payload.orderDetailId
      break
    case VIEW_GIFT_DETAILS:
      newState.viewGiftDetail = action.payload
      break
    default:
  }
  return newState
}

export default OrderDetailReducer
