import React, {FC, useEffect, useState} from 'react'
// import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Form, FormGroup, FormText, Input, Label, Row} from 'reactstrap'
import ModalUen from './ModalUen'

import {GiForm} from '../../components/formGi/FormGi'
import {RootState} from '../../redux/rootReducer'
import {ActPostRegisterCorporate} from '../../redux/corporate/CorporateActions'

import {messages} from '../../Messages'
import {getModal} from '../../redux/modal/ModalActions'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import {getCountryProfile} from '../../services/country/CountryServices'

// import {ERROR_CORPORATE} from '../../redux/corporate/CorporateActionTypes'
import './style.scss'

const FormRegister: FC = () => {
  const dispatch = useDispatch()
  const modulCorporate = useSelector((state: RootState) => state.modulCorporate)

  useEffect(() => {
    if (modulCorporate.status === 'exsits') {
      dispatch(getModal({modal: 'MODAL_EXISTS', open: true}))
    }
  }, [dispatch, modulCorporate.status])

  const [optCountry, setOptCountry] = useState([])
  const [loadCountry, setLoadCountry] = useState<boolean>(false)

  useEffect(() => {
    const getCountry = async () => {
      setLoadCountry(true)
      try {
        const rest = await getCountryProfile()
        const {code, data} = rest
        if (code === 200) {
          const datas = data.map((item: any) => {
            return {
              label: item.country_name,
              value: item.code,
            }
          })

          setOptCountry(datas)
        }
      } catch (error) {}

      setLoadCountry(false)
    }

    getCountry()
  }, [])

  // giform config
  const {errors, GiFormSubmit, giData, GiHandleChange, GiNameAndValue} = GiForm({
    fields: [
      {
        name: 'fullname',
        validations: ['required'],
        messages: {
          required: messages.register_fullname_required,
        },
      },
      {
        name: 'email',
        validations: ['required', 'email'],
        messages: {
          required: messages.register_email_required,
        },
      },
      {
        name: 'phonenumber',
        validations: ['required', 'number'],
        messages: {
          required: messages.register_phone_required,
        },
      },
      {
        name: 'businessName',
        validations: ['required'],
        messages: {
          required: messages.register_business_name_required,
        },
      },
      {
        name: 'department',
        validations: [],
        messages: {
          required: messages.register_business_name_required,
        },
      },
      {
        name: 'country',
        validations: ['required'],
        messages: {
          required: messages.register_country_required,
        },
        defaultValue: 'sg',
      },
    ],
    onSubmited: (values: any, isSubmited: boolean, errors: any) => {
      const {fullname, email, phonenumber, businessName, department, company_phone, country} =
        values
      if (isSubmited) {
        let data = {
          details: {
            personal_profile: {
              name: fullname,
              alias_name: '',
              email: email,
              mobile_phone: phonenumber,
            },
            company_profile: {
              business_name: businessName,
              department: department,
              mobile_phone: company_phone,
              country: country.toUpperCase(),
            },
            with_singpass: false,
          },
          auto_approved: false,
        }
        // console.log(data)
        // return

        return dispatch(ActPostRegisterCorporate(data))
      }
    },
  })

  const changePhoneNumber = (name: any, value: any) => {
    GiNameAndValue(name, value)
  }

  // const changePhoneNumberCompany = (name: any, value: any) => {
  //   GiNameAndValue('name', value)
  // }

  const changeCountry = (name: any, value: any) => {
    GiNameAndValue(name, value)
  }

  // let navigate = useNavigate()

  const selected = (data: any = [], value: string = '') => {
    const val: any = data.filter((item: any) => item.value === value.toUpperCase())
    return val[0] || {label: 'Please Select Country', value: ''}
  }

  const [country_phone_number, setCountry_phone_number] = useState<any>('')
  return (
    <div>
      <ModalUen />

      {modulCorporate.status !== 'success' ? (
        <Form className='form-shady' onSubmit={GiFormSubmit}>
          {/* <h4 className='mb-5 '>Personal Profile</h4> */}
          {/* <hr /> */}
          {/* <hr className='mb-5' /> */}
          <h4 className='mb-2 mt-0'>User Profile</h4>
          <FormGroup row>
            <Col sm='2' md='3' lg='3'>
              <Label>Your full name *</Label>
            </Col>
            <Col sm='' md='9' lg=''>
              <Input
                name='fullname'
                key={'1290'}
                defaultValue={giData.fullname}
                onChange={GiHandleChange}
                placeholder='Enter Full Name'
              />
              <FormText color='danger'>{errors?.fullname}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm='2' md='3' lg='3'>
              <Label>Your Email address *</Label>
            </Col>
            <Col sm='' md='9' lg=''>
              <Input
                name='email'
                defaultValue={giData.email}
                onChange={GiHandleChange}
                placeholder='john.doe@yourcompany.com '
              />
              <FormText color='danger'>{errors?.email}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm='2' md='3' lg='3'>
              <Label>Your phone number *</Label>
            </Col>
            <Col sm='' md='9' lg='' className='phone-register-b2b'>
              <PhoneInput
                autoFormat={false}
                countryCodeEditable={false}
                country={'sg'}
                onChange={(value) => changePhoneNumber('phonenumber', value)}
              />
              <FormText color='danger'>{errors?.phonenumber}</FormText>
            </Col>
          </FormGroup>
          <hr />

          <h4 className='mb-4'>Company Profile</h4>
          <FormGroup row>
            <Col sm='2' md='3' lg='3'>
              <Label>Company Name *</Label>
            </Col>
            <Col sm='10' md='9' lg=''>
              <Input
                name='businessName'
                onChange={GiHandleChange}
                placeholder='Enter Company Name'
                defaultValue={giData.businessName}
              />
              <FormText color='danger'>{errors?.businessName}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm='2' md='3' lg='3'>
              <Label>Department</Label>
            </Col>
            <Col sm='10' md='9' lg=''>
              <Input
                name='department'
                onChange={GiHandleChange}
                placeholder='Enter Department'
                defaultValue={giData.department}
              />
              <FormText color='danger'>{errors?.department}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm='2' md='3' lg='3'>
              <Label>Country *</Label>
            </Col>
            <Col sm='10' md='9' lg=''>
              <Select
                options={optCountry}
                isLoading={loadCountry}
                isDisabled={loadCountry}
                value={selected(optCountry, giData.country)}
                classNamePrefix={'refister-b2b'}
                onChange={(params: any) => changeCountry('country', params.value.toLowerCase())}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    borderColor: '#C4C4C4',
                  }),
                }}
              />
              <FormText color='danger'>{errors?.country}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row className='d-none'>
            <Col sm='2' md='3' lg='3'>
              <Label>Company phone number *</Label>
            </Col>
            <Col sm='' md='9' lg='' className='phone-register-b2b'>
              <PhoneInput
                autoFormat={false}
                countryCodeEditable={false}
                country={country_phone_number || giData.country}
                onChange={(value, country: any) => {
                  const {countryCode} = country
                  setCountry_phone_number(countryCode)
                  changePhoneNumber('company_phone', value)
                }}
                // value={giData?.company_phone}
              />
              <FormText color='danger'>{errors?.company_phone}</FormText>
            </Col>
          </FormGroup>
          {/* <FormGroup className='d-none' row>
                <Col sm='2' md='3' lg='2'>
                  <Label>Nationality</Label>
                </Col>
                <Col sm='10' md='9' lg=''>
                  <Input
                    type='text'
                    name='nationality'
                    onChange={GiHandleChange}
                    placeholder='Enter registered address'
                    defaultValue={giData.nationality}
                  />

                  <FormText color='danger'>{errors?.nationality}</FormText>
                </Col>
              </FormGroup> */}
          {/* <h4 className='mb-5 section-divider'>About Your Company</h4> */}
          {/* <hr className='mb-5' /> */}
          {/* company profile */}
          <Row>
            <Col sm='12' md='12' lg='6'>
              {/* <FormGroup row>
                    <Col sm='4' md='3' lg='4'>
                      <Label>Registration Number</Label>
                    </Col>
                    <Col sm='8' md='9' lg=''>
                      <Input
                        name='regnumber'
                        onChange={GiHandleChange}
                        placeholder='Enter registration number'
                        defaultValue={giData.regnumber}
                      />
                      <FormText color='muted' className='me-2'>
                        (UEN)
                      </FormText>
                      <FormText color='danger'>{errors?.regnumber}</FormText>
                    </Col>
                  </FormGroup> */}
            </Col>
            <Col sm='12' md='12' lg='6'>
              {/* <FormGroup row>
                    <Col className='text-lg-end' sm='3' md='3' lg='4'>
                      <Label>Registration date</Label>
                    </Col>
                    <Col sm='9' md='9' lg=''>
                      <DatePicker
                        autoComplete='off'
                        onChange={(date: any) => GiHandleDatepicker(date, 'regdate')}
                        name='regdate'
                        className='form-control'
                        value={giData?.regdate}
                        selected={giData?.regdate}
                        placeholderText='Registration date'
                        dateFormat='dd/MM/yyyy'
                      />
                      <FormText color='danger'>{errors?.regdate}</FormText>
                    </Col>
                  </FormGroup> */}
            </Col>
          </Row>
          {/* <FormGroup row>
                <Col sm='2' md='3' lg='2'>
                  <Label>Company Name</Label>
                </Col>
                <Col sm='10' md='9' lg=''>
                  <Input
                    name='businessName'
                    onChange={GiHandleChange}
                    placeholder='Enter business name'
                    defaultValue={giData.businessName}
                  />
                  <FormText color='danger'>{errors?.businessName}</FormText>
                </Col>
              </FormGroup> */}

          <Row>
            <Col sm='12' md='12' lg='6'>
              {/* <FormGroup row>
                    <Col sm='4' md='3' lg='4'>
                      <Label>Business type</Label>
                    </Col>
                    <Col sm='8' md='9' lg=''>
                      <Select
                        className='gf-select'
                        name='businessType'
                        options={companyType}
                        onChange={(value: any) => GiHanldeReactSelect(value, 'businessType')}
                        value={selected(giData.businessType, companyType)}
                      />
                      <FormText color='danger'>{errors?.businessType}</FormText>
                    </Col>
                  </FormGroup> */}
            </Col>
            <Col sm='12' md='12' lg='6'>
              {/* <FormGroup row>
                    <Col className='text-lg-end' sm='3' md='3' lg='4'>
                      <Label>Primary activity</Label>
                    </Col>
                    <Col sm='9' md='9' lg=''>
                      <Input
                        name='primaryAct'
                        onChange={GiHandleChange}
                        placeholder='Enter primary activity'
                        defaultValue={giData.primaryAct}
                      />
                      <FormText color='danger'>{errors?.primaryAct}</FormText>
                    </Col>
                  </FormGroup> */}
            </Col>
          </Row>
          {/* <FormGroup row>
                <Col sm='2' md='3' lg='2'>
                  <Label>Registered address</Label>
                </Col>
                <Col sm='10' md='9' lg=''>
                  <Input
                    name='regAddress'
                    type='textarea'
                    onChange={GiHandleChange}
                    placeholder='Enter registered address '
                    defaultValue={giData.regAddress}
                  />
                  <FormText color='danger'>{errors?.regAddress}</FormText>
                </Col>
              </FormGroup> */}
          {/* <FormGroup row>
                <Col sm='12' md='12' lg='6'>
                  <FormGroup row>
                    <Col sm='4' md='3' lg='4'>
                      <Label>Country</Label>
                    </Col>
                    <Col sm='8' md='9' lg=''>
                      <Select
                        className='gf-select'
                        name='country'
                        options={dataCountries}
                        onChange={(value: any) => GiHanldeReactSelect(value, 'country')}
                        value={selected(giData.country, dataCountries)}
                      />
                      <FormText color='danger'>{errors?.country}</FormText>
                    </Col>
                  </FormGroup>
                </Col>
              </FormGroup> */}
          <FormGroup className='text-center'>
            <Button
              type='submit'
              className='mt-5 w-40 btn btn-rounded rounded-pill'
              color='primary'
              disabled={modulCorporate.status === 'loading'}
            >
              {modulCorporate.status === 'loading' ? 'loading..' : 'Create Account'}
            </Button>
          </FormGroup>
        </Form>
      ) : (
        <>
          <h2 className='text-center titling'>THANK YOU</h2>
          <div className='text-center'>
            <p className='subline'>Thank you for creating an account with us.</p>
            <p className='subline'>
              We have received your request and will come back to you shortly.
            </p>
            <p className='subline'>
              This typically takes a few hours but will often happen much more quickly. No further
              action is required on your part. Once we have complete our review your information, we
              will proceed to set up your account.
            </p>
            <p className='subline'>We appreciate your patience,</p>
            <span className='text-primary font-16-bold'>Team Giftano</span>
          </div>
        </>
      )}
    </div>
  )
}

export default FormRegister
