import {CONFIG, CONFIG_CP, CONFIG_STRAPI_CONTENT, CONNECTION} from './connection'
import {log} from './logger/Log'
import CryptoJS from 'crypto-js'

export const appName = process.env.REACT_APP_NAME || 'b2b-dashboard'

export const getXAppName = () => {
  const encrypted = CryptoJS.AES.encrypt(appName, window.location.hostname).toString()
  return encrypted
}

export const toTitleCase = (strValue) => {
  return strValue.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export {CONFIG, CONFIG_CP, CONFIG_STRAPI_CONTENT, CONNECTION, log}
