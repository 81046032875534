import {Dispatch} from 'redux'
import {IAction} from '../store'
import {getAllContentByTag} from '../../services/content/ContentServices'
import {
  ERROR_APP_STRING_CORPORATE,
  LOADING_APP_STRING_CORPORATE,
  SUCCESS_APP_STRING_CORPORATE,
} from './AppStringCorporateTypes'
import * as Sentry from '@sentry/react'

export const actGetAppStringCorporate =
  (corporate_id: String) => async (dispatch: Dispatch<IAction>) => {
    dispatch({type: LOADING_APP_STRING_CORPORATE})
    try {
      let res = await getAllContentByTag(`fe-app-string-business-dashboard-${corporate_id}`)
      if (res.code === 200 && res.data) {
        //map data into obj
        let newData: any = {}
        for (let item of res.data) {
          newData[item.slug] = item.descriptions
        }
        dispatch({type: SUCCESS_APP_STRING_CORPORATE, payload: {data: newData}})
      } else {
        dispatch({type: SUCCESS_APP_STRING_CORPORATE, payload: {data: {}}})
      }
    } catch (err) {
      dispatch({type: ERROR_APP_STRING_CORPORATE, payload: {error: err}})
      Sentry.captureException(err)
    }
  }
