/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../app/redux/rootReducer'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import * as BsIcon from 'react-icons/bs'
import * as FaIcon from 'react-icons/fa'
import * as MdIcon from 'react-icons/md'
var BsIcons: any = BsIcon
var FaIcons: any = FaIcon
var MdIcons: any = MdIcon

export function AsideMenuMain() {
  const dataMenu: any[] = useSelector(
    (state: RootState) => state.permissionMenu.data,
    shallowEqual
  ).sort((a, b) => a.order - b.order)

  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const strapi = useSelector((state: RootState) => state.strapi)

  const magicCard = process.env.REACT_APP_QRGIFT_NAME || 'Magic Card'
  const magicCardPath = magicCard.toLowerCase().replace(' ', '-')

  //restructure json menu
  const getChildren: any = (id: string) => {
    var itemIcon: string = 'FaHome'
    let icon: any = ''

    return dataMenu
      .filter((i) => i.parent_id === id)
      .map((item, i) => {
        itemIcon = item.icon || 'FaHome'
        icon = React.createElement(
          FaIcons[itemIcon] || BsIcons[itemIcon] || MdIcons[itemIcon] || 'FaHome'
        )
        var key: number = i + 1

        return {
          ...item,
          icon: icon,
          id: item.id,
          key: key.toString(),
          child: getChildren(item.menu_id),
        }
      })
  }

  // console.log('corporate_detail', corporate_detail)

  let menu = []
  if (corporate_detail) {
    if (corporate_detail.is_api_account?.toString() === '1') {
      menu = getChildren(
        dataMenu.filter((i) => i.name === 'partnership_menu').map((i) => i.menu_id)[0]
      )
      menu = menu.filter((i: any) => i.name !== 'FAQs')
    } else if (corporate_detail.type === 'corporate') {
      menu = getChildren(dataMenu.filter((i) => i.name === 'main_menu').map((i) => i.menu_id)[0])
    } else if (corporate_detail.type === 'partnership') {
      menu = getChildren(
        dataMenu.filter((i) => i.name === 'partnership_menu').map((i) => i.menu_id)[0]
      )
    }
    let appEnableQrGift = strapi?.data?.enable_qr_gift || false
    let transactionSettings = corporate_detail['transaction-settings']
    if (!appEnableQrGift) {
      menu = menu.filter((i: any) => i.url !== `/about-${magicCardPath}`)
    }
    let showLogs = transactionSettings['gfb-show-activity-logs'] || '0'
    if (showLogs === '0') {
      menu = menu.filter((i: any) => i.url !== '/corporate-activities')
    }
  }

  const renderMenuItem = (item: any) => {
    if (item.child && item.child.length > 0) {
      return (
        <AsideMenuItemWithSub
          hasBullet={false}
          key={item.key}
          to={item.url}
          title={item.name}
          icon={item.icon}
        >
          {item.child.map((subMenuItem: any, i: number) => {
            var k = subMenuItem.key + i.toString()

            return renderMenuItem({
              url: subMenuItem.url,
              name: subMenuItem.name,
              id: subMenuItem.id,
              key: k,
            })
          })}
        </AsideMenuItemWithSub>
      )
    } else {
      return (
        <AsideMenuItem
          hasBullet={false}
          key={item.id}
          to={item.url}
          title={item.name}
          icon={item.icon}
          isRestricted={corporate_detail.is_verified !== '1' ? true : false}
        />
      )
    }
  }

  return (
    <>
      {menu.map((menuItem: any, i: number) => renderMenuItem(menuItem))}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
