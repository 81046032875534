import {CONFIG, CONFIG_CP, CONNECTION} from '../../helper'

const path: any = process.env.REACT_APP_PATH_CORPORATE
const baseLegacy = process.env.REACT_APP_BASEURL_LEGACY

export interface postCorporatePotential {
  details: {
    personal_profile: any // email, name, mobile_phone
    company_profile: any // business_name, country, mobile_phone
    with_singpass: boolean //
  }
  auto_approved?: boolean
}

export interface checkUenCorporateParams {
  uen: any
}

export const postCorporate = async (params: postCorporatePotential) => {
  const config = CONFIG({url: path + '/potential/create', data: params, method: 'POST'})

  return await CONNECTION(config)
}

export const checkUenCorporate = async (params: any) => {
  const config = CONFIG_CP({url: baseLegacy + '/cp/company/list/', data: params})
  return await CONNECTION(config)
}

export const requestForApproval = async ({corporate_id}: {corporate_id: string}) => {
  const config = CONFIG({
    url: path + '/potential/verification',
    method: 'POST',
    data: {
      action: 'request-approval',
      corporate_id: corporate_id,
    },
  })
  return await CONNECTION(config)
}

export interface corporateOrders {
  corporate_id: string
  order_status: string
  date_start?: string
  date_end?: string
  page?: number
  limit?: number
}

export const getCorporateOrdersGO = async ({
  corporate_id,
  order_status,
  page = 1,
  limit = 10,
}: corporateOrders) => {
  let config = CONFIG({
    url:
      path +
      '/order/get/all?corporate_id=' +
      corporate_id +
      '&status=' +
      order_status +
      '&page=' +
      page +
      '&limit=' +
      limit,
    // method: 'GET',
    // data: {
    //   corporate_id: corporate_id,
    //   ord_status: order_status,
    //   limit: 10,
    //   page: 2
    // },
  })
  return await CONNECTION(config)
}

export const paymentOrder = async (data: any) => {
  let config = CONFIG({
    url: path + '/order/payment',
    data: data,
    method: 'POST',
  })
  return await CONNECTION(config)
}

export const OrderGiftUpdate = async (data: any) => {
  let with_Order_id = ''
  if (data?.gift_id) {
    with_Order_id = `/${data?.gift_id}`
  }
  let config = CONFIG({
    url: path + `/order/gift/update/${data?.order_id}${with_Order_id}`,
    data: data?.details,
    method: 'PUT',
  })
  return await CONNECTION(config)
}
