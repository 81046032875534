import {FC, lazy, Suspense, useEffect} from 'react'
import {Route, Navigate, Routes} from 'react-router-dom'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import MasterLayout from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../redux/rootReducer'
import {getRoutesAct} from '../redux/app/RouteAction'
import BlankLayout from '../../_metronic/layout/BlankLayout'
import ErrorBoundary from '../pages/errors/ErrorBoundary'

const PrivateRoutes = () => {
  const routerState = useSelector((state: RootState) => state.router, shallowEqual)
  const dispatch = useDispatch()

  useEffect(() => {
    if (routerState.status === 'pending') {
      dispatch(getRoutesAct())
    }
  }, [dispatch, routerState.status])
  return (
    <Routes>
      {routerState.data.map((route) => {
        const ElementPage = lazy(() => import(`../${route.component}`))
        let ElementLayout
        switch (route.layout) {
          case 'Blank':
            ElementLayout = <BlankLayout />
            break
          default:
            ElementLayout = <MasterLayout />
        }
        return (
          <Route key={'route_' + route.id} element={ElementLayout}>
            <Route
              path={route.path}
              element={
                <ErrorBoundary fallback={<Navigate to='/error/500' />}>
                  <SuspensedView>
                    <ElementPage />
                  </SuspensedView>
                </ErrorBoundary>
              }
            />
          </Route>
        )
      })}

      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
