import {CONFIG, CONNECTION} from '../../helper'

const endpoint = process.env.REACT_APP_ENDPOINT_CONTENT

export const getAllContentByTag = async (tagId: string) => {
  const config = CONFIG({
    url: endpoint + '/get-bytag/' + tagId,
  })
  return await CONNECTION(config)
}
