import {combineReducers} from 'redux'
import {AppReducer, RouteReducer} from './app'
import {
  AuthReducer,
  modulCheckingUser,
  PermissionMenuReducer,
  PermissionModulesReducer,
} from './auth'
import CatalogueReducer from './catalogue/CatalogueReducer'
import {AccountBalanceReducer, DraftOrderReducer} from './corporate'
import {ModalReducer} from './modal'
import OrderReducer from './order/OrderReducer'
import SoaBalanceReducer from './soa/SoaBalanceActions'
import SoaReducer from './soa/SoaReducer'
import RoleReducer from './user/RoleReducer'
import UserManagement from './user/UserManagementReducer'
import CountryReducer from './country/CountryReducer'
import CorporateReducer from './corporate/CorporateReducer'
import BlogReducer from './blog/BlogReducer'
import StrapiReducer from './strapi/StrapiReducer'
import TransactionReducer from './transactions/TransactionReducer'
import {AppStringReducer} from './app-string'
import ContactUsReducer from './contact-us/ContactUsReducer'
import SoaInvoiceReducer from './soa/SoaInvoiceReducer'
import OrderGoReduce from './orderGo/OrderGoReducer'
import GreetingCategoryReducer from './greeting-catagory/GreetingCatoryReducers'
import WhitelabelListReducer from './whitelabel-list/WhitelabelListReducer'
import modulFinanceGroup from './transfer/FinanceGroupReducer'
import modulTransfer from './transfer/TransferReducer'
import SelectCreateGiftReducer from './select-create-gift/SelectCreateGiftReducer'
import CreditCardReducer from './credit-card/CreditCardReducer'
import CreateOrderReducer from './create-order/CreateOrderReducer'
import PattrenPassword from './pattren/PasswordLib'
import AvailableCountryReducer from './available-country/AvailableCountryReducer'
import CatalogueOtherCountryReducer from './catalogue-other-country/CatalogueOtherCountryReducer'
import HelpModuleReducer from './help-page/HelpReducer'
import OrderDetailReducer from './order-details/OrderDetailReducer'
import AppStringCorporateReducer from './app-string-corporate/AppStringCorporateReducer'

const rootReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  permissionMenu: PermissionMenuReducer,
  permissionModules: PermissionModulesReducer,
  router: RouteReducer,
  modal: ModalReducer,
  roleUser: RoleReducer,
  userManagement: UserManagement,
  modulCatalogue: CatalogueReducer,
  modulSoa: SoaReducer,
  modulSoaBalance: SoaBalanceReducer,
  draftOrder: DraftOrderReducer,
  order: OrderReducer,
  orderGo: OrderGoReduce,
  modulCountry: CountryReducer,
  modulCorporate: CorporateReducer,
  accountBalance: AccountBalanceReducer,
  modulBlog: BlogReducer,
  modulCheckingUser: modulCheckingUser,
  strapi: StrapiReducer,
  transaction: TransactionReducer,
  appString: AppStringReducer,
  appStringCorporate: AppStringCorporateReducer,
  contactUs: ContactUsReducer,
  selectCreateGift: SelectCreateGiftReducer,
  modulSoaInvoice: SoaInvoiceReducer,
  greetingCategory: GreetingCategoryReducer,
  whitelabelList: WhitelabelListReducer,
  modulFinanceGroup: modulFinanceGroup,
  modulTransfer: modulTransfer,
  creditCard: CreditCardReducer,
  createOder: CreateOrderReducer,
  modulPasswordPattren: PattrenPassword,
  availableCountry: AvailableCountryReducer,
  catalogueOtherCountry: CatalogueOtherCountryReducer,
  HelpModule: HelpModuleReducer,
  orderDetail: OrderDetailReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
