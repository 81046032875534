import {CONFIG_CP, CONNECTION} from '../../helper/connection/connection'

const baseLegacy = process.env.REACT_APP_BASEURL_LEGACY

export interface getCorporateOrdersParams {
  corporateid: string
  userid: string
}
export interface getCorporateOrderDetailParams {
  orderid: string
  corporateid: string
  userid: string
  getEmailReport?: number
}
export interface getAccountBalanceParams {
  corporateid: string
}

export const getCorporateOrders = async ({corporateid, userid}: getCorporateOrdersParams) => {
  let fd = new FormData()
  fd.append('corporateid', corporateid)
  fd.append('userid', userid)
  fd.append('mode', '')
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate-self-order/get-orders/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const getCorporateOrderDetails = async ({
  orderid,
  corporateid,
  userid,
  getEmailReport,
}: getCorporateOrderDetailParams) => {
  let fd = new FormData()
  fd.append('corporateid', corporateid)
  fd.append('userid', userid)
  fd.append('orderid', orderid)
  fd.append('show_details', '2')
  if (getEmailReport) {
    fd.append('get_email_report', getEmailReport.toString())
  }
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate-self-order/get-order/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const getAccountBalance = async ({corporateid}: getAccountBalanceParams) => {
  let fd = new FormData()
  fd.append('corporateid', corporateid)
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate/current-credit/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const topupCredits = async ({
  corporateid,
  braintree_id,
  amount,
  orderid,
}: {
  corporateid: string
  braintree_id: string
  amount: number
  orderid?: string
}) => {
  let fd = new FormData()
  fd.append('corporateid', corporateid)
  fd.append('braintree_id', braintree_id)
  fd.append('amount', amount.toString())
  if (orderid) {
    fd.append('orderid', orderid)
  }
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate/topup-credits/braintree/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const createUpdateOrderWithItems = async ({
  corporateid,
  items,
  userid,
  title,
  invoice_date,
  created_by,
  theme,
  url_recipient,
  notes,
}: {
  corporateid: string
  items: any[]
  userid: string
  title: string
  invoice_date: string
  created_by: string
  theme: string
  url_recipient: string
  notes: string
}) => {
  let fd = new FormData()
  fd.append('corporateid', corporateid)
  fd.append('userid', userid)
  fd.append('items', JSON.stringify(items))
  fd.append('title', title)
  fd.append('invoice_date', invoice_date)
  fd.append('created_by', created_by)
  fd.append('theme', theme)
  fd.append('notes', notes)
  if (url_recipient) {
    fd.append('url_recipients', url_recipient)
    fd.append('send_link', '1')
  }

  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate-self-order/create-update-order-with-items/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const createCorporateOrderPayment = async ({
  orderid,
  corporateid,
  payment_method,
  userid,
  paid_by,
  requester_name,
}: {
  orderid: string
  corporateid: string
  payment_method: 'braintree' | 'optWTG36ZVF' | 'purchase-order' | 'bank-transfer'
  userid: string
  paid_by: string
  requester_name: string
}) => {
  let fd = new FormData()
  fd.append('orderid', orderid)
  fd.append('corporateid', corporateid)
  fd.append('payment_method', payment_method)
  fd.append('userid', userid)
  fd.append('paid_by', paid_by)
  fd.append('requester_name', requester_name)
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate-self-order/payment/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const getEmailPreview = async ({
  product_id,
  company_id,
}: {
  product_id: string
  company_id: string
}) => {
  let fd = new FormData()
  fd.append('product_id', product_id)
  fd.append('company_id', company_id)
  let config = CONFIG_CP({
    url: baseLegacy + '/public/email_preview/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const resendGift = async ({
  corporateid,
  order_id,
  transaction_id,
  userid,
}: {
  corporateid: string
  order_id: string
  transaction_id: string
  userid: string
}) => {
  let fd = new FormData()
  fd.append('corporateid', corporateid)
  fd.append('order_id', order_id)
  fd.append('transaction_id', transaction_id)
  fd.append('userid', userid)
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate-self-order/resend-gift/by-transaction/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const getCorporateDetail = async ({corporateid}: {corporateid: string}) => {
  let fd = new FormData()
  fd.append('id', corporateid)
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate/profile/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const uploadLogo = async ({corporateid, url}: {corporateid: string; url: string}) => {
  let fd = new FormData()
  fd.append('id', corporateid)
  fd.append('url', url)
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate/logo/upload/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const updateCorporateSetting = async ({
  corporateid,
  key,
  value,
}: {
  corporateid: string
  key: string
  value: string
}) => {
  let fd = new FormData()
  fd.append('id', corporateid)
  fd.append('name', 'transaction-settings')
  fd.append('value', key)
  fd.append('text', value)
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate/options/autocreate/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const requestForApproval = async (data: {
  orderid: string
  corporateid: string
  userid: string
  file_url: string
  requester_name: string
  po_number?: string
  procurement_platform?: string
  procurement_platform_notes?: string
  payment_terms?: string
  is_purchase_order: number
  payment_method: string
}) => {
  let fd = new FormData()
  fd.append('userid', data.userid)
  fd.append('orderid', data.orderid)
  fd.append('corporateid', data.corporateid)
  fd.append('file_url', data.file_url)
  fd.append('requester_name', data.requester_name)
  fd.append('is_purchase_order', data.is_purchase_order.toString())
  fd.append('payment_method', data.payment_method)
  if (data.po_number) fd.append('po_number', data.po_number)
  if (data.procurement_platform) fd.append('procurement_platform', data.procurement_platform)
  if (data.procurement_platform_notes)
    fd.append('procurement_platform_notes', data.procurement_platform_notes)
  if (data.payment_terms) fd.append('payment_terms', data.payment_terms)
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/corporate-self-order/request-for-approval/',
    data: fd,
  })
  return await CONNECTION(config)
}

export const merchantOptionsList = async (data: {name: string}) => {
  let fd = new FormData()
  fd.append('name', data.name)
  let config = CONFIG_CP({
    url: baseLegacy + '/merchant/optionslist/',
    data: fd,
  })
  return await CONNECTION(config)
}
