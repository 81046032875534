import LayoutConfirm from '../confirm/LayoutConfirm'
import {useState, useEffect} from 'react'

const ModalWarningReload = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleOpenModal = () => {
      setIsOpen(true)
    }

    window.addEventListener('openModalWarningReload', handleOpenModal)

    return () => {
      window.removeEventListener('openModalWarningReload', handleOpenModal)
    }
  }, [])

  return (
    <LayoutConfirm
      onClose={() => setIsOpen(false)}
      hideClose
      onAccept={() => {
        window.location.reload()
      }}
      textAccept='Reload'
      buttonAcceptProps={{
        color: 'primary',
        className: 'text-white',
      }}
      title={'Oops! Something went wrong'}
      message={
        'Looks like your session expired due to inactivity. Please reload the page to continue. Sorry for the inconvenience!'
      }
      isOpen={isOpen}
    />
  )
}

export default ModalWarningReload
